const generateUUID = () => {
  return "api_doku_xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    let r = (Math.random() * 16) | 0, v = c == "x" ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

document.addEventListener("turbolinks:load", () => {
  const el = document.querySelector('[data-button="generateUUID"]')

  if (el) {
    el.addEventListener("click", () => {
      const hiddenUUID = document.querySelector('[data-field="hiddenUUID"]')
      const showUUID = document.querySelector('[data-field="showUUID"]')
      const uuid = generateUUID()

      hiddenUUID.value = uuid
      showUUID.value = uuid
    })
  }
})
